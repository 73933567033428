import { CONTACT_US_URL } from '@mindfulchefuk/constants'

type TSocialLinks = {
  id: number | string
  facebook: string
  twitter: string
  instagram: string
}

type TNavigationItem = {
  title: string
  path: string | null
  order: number
  target: string | null
  reference: string
  items?: TNavigationItem[]
}

type TGlobalContentFallBack = {
  createdAt: string
  updatedAt: string
  socialLinks: TSocialLinks
  navigation: {
    footer_signed_in: TNavigationItem[]
    footer: TNavigationItem[]
  }
}

export const globalContentFallback: TGlobalContentFallBack = {
  createdAt: '2023-04-05T09:27:06.405Z',
  updatedAt: '2023-04-05T09:27:06.405Z',
  socialLinks: {
    id: 1,
    facebook: 'https://www.facebook.com/MindfulChefUK',
    twitter: 'https://twitter.com/mindfulchefUK',
    instagram: 'https://www.instagram.com/mindfulchefuk',
  },
  navigation: {
    footer_signed_in: [
      {
        title: 'About Us',
        path: null,
        order: 0,
        target: null,
        reference: 'about-us',
        items: [
          {
            title: 'Our approach',
            path: 'https://www.mindfulchef.com/approach',
            order: 0,
            target: '_blank',
            reference: 'approach',
          },
          {
            title: 'One Feeds Two',
            path: 'https://www.mindfulchef.com/one-feeds-two',
            order: 1,
            target: '_blank',
            reference: 'one-feed-two',
          },
          {
            title: 'Recycling guide',
            path: 'https://mindfulchef-cms-production.s3.eu-west-2.amazonaws.com/defaults/Recycling_Guides23.pdf',
            order: 2,
            target: '_blank',
            reference: 'recycling-guide',
          },
          {
            title: 'Our team',
            path: 'https://www.mindfulchef.com/team',
            order: 3,
            target: '_blank',
            reference: 'team',
          },
          {
            title: 'Proud to be a B-Corp',
            path: 'https://www.mindfulchef.com/b-corp',
            order: 4,
            target: '_blank',
            reference: 'b-corp',
          },
          {
            title: 'Modern Slavery Statement',
            path: 'https://www.mindfulchef.com/modern-slavery-statement',
            order: 5,
            target: '_blank',
            reference: 'modern-slavery-statement',
          },
          {
            title: 'Careers',
            path: 'https://www.mindfulchef.com/team',
            order: 6,
            target: '_blank',
            reference: 'careers',
          },
        ],
      },
      {
        title: 'Recipes',
        path: null,
        order: 1,
        target: null,
        reference: 'recipe-box-delivery',
        items: [
          {
            title: 'Search recipes',
            path: '/healthy-recipes',
            order: 0,
            target: '_self',
            reference: 'recipes',
          },
          {
            title: 'Buy our recipe book',
            path: 'https://www.amazon.co.uk/Mindful-Chef-Myles-Hopper/dp/1780896697/ref=as_sl_pc_qf_sp_asin_til?tag=mindfulchefuk-20&linkCode=w00&linkId=&creativeASIN=1780896697',
            order: 1,
            target: '_blank',
            reference: 'book',
          },
          {
            title: 'Browse suppliers',
            path: 'https://www.mindfulchef.com/suppliers',
            order: 2,
            target: '_blank',
            reference: 'suppliers',
          },
        ],
      },
      {
        title: 'Explore',
        path: null,
        order: 3,
        target: null,
        reference: 'explore',
        items: [
          {
            title: 'Gift cards',
            path: '/gift-cards',
            order: 0,
            target: '_self',
            reference: 'gift-cards',
          },
          {
            title: 'Blog',
            path: 'https://www.mindfulchef.com/blog',
            order: 1,
            target: '_blank',
            reference: 'blog',
          },
        ],
      },
      {
        title: 'Contact Us',
        path: null,
        order: 4,
        target: null,
        reference: 'contact-us',
        items: [
          {
            title: 'Get in touch',
            path: CONTACT_US_URL,
            order: 0,
            target: '_blank',
            reference: 'get-in-touch',
          },
          {
            title: 'FAQs',
            path: 'https://help.mindfulchef.com/',
            order: 1,
            target: '_blank',
            reference: 'faqs',
          },
          {
            title: 'Terms',
            path: 'https://www.mindfulchef.com/info/terms-and-conditions',
            order: 2,
            target: '_blank',
            reference: 'terms',
          },
          {
            title: 'Privacy',
            path: 'https://www.mindfulchef.com/info/privacy-policy',
            order: 3,
            target: '_blank',
            reference: 'privacy',
          },
        ],
      },
    ],
    footer: [
      {
        title: 'About Us',
        path: null,
        order: 0,
        target: null,
        reference: null,
        items: [
          {
            title: 'Our approach',
            path: 'https://www.mindfulchef.com/approach',
            order: 0,
            target: '_blank',
            reference: 'approach',
          },
          {
            title: 'One Feeds Two',
            path: 'https://www.mindfulchef.com/one-feeds-two',
            order: 1,
            target: '_blank',
            reference: 'one-feed-two',
          },
          {
            title: 'Recycling guide',
            path: 'https://mindfulchef-cms-production.s3.eu-west-2.amazonaws.com/defaults/Recycling_Guides23.pdf',
            order: 2,
            target: '_blank',
            reference: 'recycling-guide',
          },
          {
            title: 'Our team',
            path: 'https://www.mindfulchef.com/team',
            order: 3,
            target: '_blank',
            reference: 'team',
          },
          {
            title: 'Proud to be a B-Corp',
            path: 'https://www.mindfulchef.com/b-corp',
            order: 4,
            target: '_blank',
            reference: 'b-corp',
          },
          {
            title: 'Modern Slavery Statement',
            path: 'https://www.mindfulchef.com/modern-slavery-statement',
            order: 5,
            target: '_blank',
            reference: 'modern-slavery-statement',
          },
          {
            title: 'Careers',
            path: 'https://www.mindfulchef.com/team',
            order: 6,
            target: '_blank',
            reference: 'careers',
          },
        ],
      },
      {
        title: 'Explore',
        path: null,
        order: 2,
        target: null,
        reference: 'explore',
        items: [
          {
            title: 'Search recipes',
            path: '/healthy-recipes',
            order: 0,
            target: '_self',
            reference: 'recipes',
          },
          {
            title: 'Buy our recipe book',
            path: 'https://www.amazon.co.uk/Mindful-Chef-Myles-Hopper/dp/1780896697/ref=as_sl_pc_qf_sp_asin_til?tag=mindfulchefuk-20&linkCode=w00&linkId=&creativeASIN=1780896697',
            order: 1,
            target: '_blank',
            reference: 'book',
          },
          {
            title: 'Blog',
            path: 'https://www.mindfulchef.com/blog',
            order: 2,
            target: '_blank',
            reference: 'blog',
          },
        ],
      },
      {
        title: 'Recipe box delivery',
        path: null,
        order: 3,
        target: null,
        reference: 'recipe-box-delivery',
        items: [
          {
            title: 'On the menu',
            path: '/choose-recipes',
            order: 3,
            target: '_self',
            reference: 'on-the-menu',
          },
          {
            title: 'Suppliers',
            path: 'https://www.mindfulchef.com/suppliers',
            order: 4,
            target: '_blank',
            reference: 'suppliers',
          },
          {
            title: 'Gift Cards',
            path: '/gift-cards',
            order: 5,
            target: '_self',
            reference: 'gift-cards',
          },
        ],
      },
      {
        title: 'Contact Us',
        path: null,
        order: 4,
        target: null,
        reference: 'contact-us',
        items: [
          {
            title: 'Get in touch',
            path: CONTACT_US_URL,
            order: 0,
            target: '_blank',
            reference: 'get-in-touch',
          },
          {
            title: 'FAQs',
            path: 'https://help.mindfulchef.com/',
            order: 1,
            target: '_blank',
            reference: 'faqs',
          },
          {
            title: 'Terms',
            path: 'https://www.mindfulchef.com/info/terms-and-conditions',
            order: 2,
            target: '_blank',
            reference: 'terms',
          },
          {
            title: 'Privacy',
            path: 'https://www.mindfulchef.com/info/privacy-policy',
            order: 3,
            target: '_blank',
            reference: 'privacy',
          },
        ],
      },
    ],
  },
}
