import React, { ReactElement, ReactNode } from 'react'
import NextLink from 'next/link'
import { Button, ButtonProps } from '@mindfulchefuk/design-system/Button'
import { useNavClickHandler } from '@mindfulchefuk/features/Navigation/hooks'

type TNavCTAType = 'primary' | 'secondary' | 'secondary-white'

type TNavCTA = {
  children: ReactNode
  fullWidth?: boolean
  href?: string
  onClick?: (labelId: string) => void
  size?: ButtonProps['size']
  testId: string
  variant: TNavCTAType
}

export const NavCTA = ({
  variant,
  testId,
  onClick,
  children,
  href,
  size,
  fullWidth = false,
}: TNavCTA): ReactElement => {
  const handleClick = useNavClickHandler({
    action: 'nav-cta-clicked',
    label: testId,
    onClick,
  })

  return (
    <NextLink href={href} passHref>
      <Button
        as="a"
        data-testid={testId}
        onClick={handleClick}
        variant={variant}
        size={size ?? { base: 'small', lg: 'medium' }}
        fullWidth={fullWidth}
      >
        {children}
      </Button>
    </NextLink>
  )
}
