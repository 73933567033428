import React, { ReactElement } from 'react'
import NextLink from 'next/link'

import { BoxProps, Flex } from '@mindfulchefuk/design-system'
import routesConfig from '@mindfulchefuk/config/routesConfig'
import { useIsNewCustomer } from '@mindfulchefuk/features/Onboarding/hooks/useIsNewCustomer'
import { usePageSource } from '@mindfulchefuk/utils/analytics/amplitude/hooks/usePageSource'
import { trackAnalyticsEvent } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

type TLogoLinkWrapper = {
  disableLink: boolean
  display: BoxProps['display']
  logo: ReactElement
}

export const LogoLinkWrapper = ({
  logo,
  display,
  disableLink,
}: TLogoLinkWrapper): ReactElement => {
  const { isCustomer, isNewCustomer } = useIsNewCustomer()
  const pageSource = usePageSource()

  if (!logo) return null

  if (disableLink) {
    return (
      <Flex
        onClick={() => {
          // currently we are only track the signed in navigation
          if (isCustomer) {
            trackAnalyticsEvent({
              name: 'nav link clicked',
              data: {
                'link name': 'mc-logo',
                section: 'top nav',
                'page source': pageSource,
              },
            })
          }
        }}
        as="a"
        display={display}
      >
        {logo}
      </Flex>
    )
  }

  const logoUrl =
    isCustomer && !isNewCustomer
      ? routesConfig.upcoming.pathname
      : routesConfig.home.pathname

  return (
    <NextLink href={logoUrl} passHref>
      <Flex
        onClick={() => {
          // currently we are only track the signed in navigation
          if (isCustomer) {
            trackAnalyticsEvent({
              name: 'nav link clicked',
              data: {
                'link name': 'mc-logo',
                section: 'top nav',
                'page source': pageSource,
              },
            })
          }
        }}
        as="a"
        display={display}
      >
        {logo}
      </Flex>
    </NextLink>
  )
}
